import '../sass/style.scss'

import 'bootstrap'
import './forms/dmdCreateEditFrm'
import './forms/dmdReponseFrm'
import './forms/dmdClotureFrm'
import './forms/userFrm'
import ajaxResponder from './ajax/responser'
import 'tablesorter';

document.addEventListener("DOMContentLoaded", function () {

    let $body = $('body');

    $("#tableSort").tablesorter({
        sortList: [[1,0]]
    });

    /* Plugins Functions */
    $('.tips').tooltip();
    $('.date').datepicker({autoclose: true, language: 'fr'});
    /* Plugins Functions */

    /* Set/unset user archive mode */
    $('#Arc').click(function (event) {
        event.preventDefault();
        $('#ARCH').val('true');
        $('#userFrm')[0].submit();
    });
    $('#Act').click(function (event) {
        event.preventDefault();
        $('#ARCH').val('false');
        $('#userFrm').submit();
    });
    /* Set/unset user archive mode */

    /* Request export dmd list */
    $('#export-demand-list').click(function (event) {
        event.preventDefault();
        let $this = $(this);
        $this.parent().find('.alert').remove();
        $.ajax({
            url: URL_SITE + 'demandes/export',
            type: 'POST',
            data: {
                Id: $('#Id').val(),
                Ida: $('#Ida').val(),
                Idc: $('#Idc').val(),
                Ids: $('#Ids').val(),
                Lib: $('#Lib').val(),
                Idp: $('#Idp').val(),
                Dtdb: $('#Dtdb').val(),
                Dtfin: $('#Dtfin').val(),
                OrderBy: $this.data('orderby'),
                Order: $this.data('order')

            },
        }).done(function (response) {
            let message;
            let messageType;

            let data = jQuery.parseJSON(response);
            if (data.hasOwnProperty('success')) {
                message = data.success;
                messageType = 'success';
            } else {
                message = data.error;
                messageType = 'danger';
            }
            ajaxResponder('#main-ajax-result', message, messageType);
        }).fail(function (data) {

        });

    });
    /* Request export dmd list */

    /* Advanced Search */
    let $triggerAdvancedSearch = $('#trigger-advanced-search');
    $triggerAdvancedSearch.on('click', function (event) {
        event.preventDefault();
        let $simpleSearchField = $('#ChampMultCrit');
        let $simpleSearchBtn = $('#btn-simple-search');
        let $triggerAdvancedSearch = $('#trigger-advanced-search');
        let $advancedSearch = $('#advanced-search');

        $triggerAdvancedSearch.toggleClass('advanced-search-active');
        $simpleSearchField.prop('disabled', (i, v) => !v).val('');
        $simpleSearchBtn.prop('disabled', (i, v) => !v);
        $simpleSearchBtn.attr('aria-disabled', (i, v) => !v);
        $advancedSearch.fadeToggle('normal');
    });
    if ($('.advanced-search-open').length) {
        $triggerAdvancedSearch.trigger('click');
    }
    $('#trigger-save-search').click(function (event) {
        event.preventDefault();
        let $this = $(this);
        $this.parent().find('.alert').remove();
        $.ajax({
            url: URL_SITE + 'demandes/ajax_save_search',
            type: 'POST',
            data: $('#AdvancedSearchFrm').serialize()
        }).done(function (response) {
            let message;
            let messageType;

            if (response) {
                message = 'Recherche enregistrée avec succès';
                messageType = 'success';
            } else {
                message = 'Aucune recherche effectuée !';
                messageType = 'danger';
            }
            ajaxResponder('#main-ajax-result', message, messageType);
        }).fail(function (data) {
        });
    });
    /* Advanced Search */

    /* Obs helper */
    let $textAreaWithHelper = $('.textarea-with-helper');
    $textAreaWithHelper.on('focus', function () {

        $(this).next('.textarea-info').addClass('translated');
        /*        $(this).next('.textarea-info').css({
                    'transform': 'translateX(-100%)',
                    'opacity': 1,
                });*/
    });
    $textAreaWithHelper.on('focusout', function () {
        $(this).next('.textarea-info').removeClass('translated');
        /*        $(this).next('.textarea-info').css({
                    'transform': 'translateX(0)',
                    'opacity': 0,
                });*/
    });
    /* Obs helper */

    /* Demand Load More */
    if ($('#dmdSuiviContent').data('nbpage') > 1) {
        $(window).on('scroll.loadMore', function () {
            if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
                let $demandListParent = $("#dmdSuiviContent");
                let currentPage = $demandListParent.attr('data-currentpage');
                let nextPage = parseInt(currentPage, 10) + 1;
                let orderby = $demandListParent.attr('data-orderby');
                let order = $demandListParent.attr('data-order');

                $.ajax({
                    url: URL_SITE + 'demandes/' + nextPage + '/' + orderby + '/' + order
                }).done(function (response) {
                    //console.log('loading... ' + response);
                    let data = jQuery.parseJSON(response);
                    if (data.hasOwnProperty('view')) {
                        $demandListParent.attr('data-currentpage', nextPage);
                        $demandListParent.append(data.view);
                    } else {
                        $('#loading-more').text('Fin de la liste');
                        $(window).off('scroll.loadMore');
                    }
                    $('.ajax-content-' + nextPage).animate({'opacity': 1}, 300);
                    //console.log('loaded!!!');
                }).fail(function (data) {
                    console.log('Ajax Error');
                });
            }
        });
    }
    /* Demand Load More */

    /* User Alerts */
    $body.on('click', '.dismiss-user-alerte', function (event) {
        event.preventDefault();
        console.log('dismissing user alert');
        let $this = $(this);
        let $alertCounter = $('#user-alert-counter');
        let alertId = $this.data('alerteid');

        $('#user-alerts-modal .ajax-loader-wrapper').css('display', 'flex');

        $.ajax({
            url: URL_SITE + 'user/ajax_dismiss_alert/' + alertId,
            type: 'GET',
        }).done(function (response) {
            console.log(response);
            let data = jQuery.parseJSON(response);
            let message;
            let messageType;

            if (data !== null && data.hasOwnProperty('error')) {
                message = data.error;
                messageType = 'danger';
            } else {
                /* Decrement alertes counter*/
                let cnt = parseInt($alertCounter.text(), 10);
                let newCnt = cnt - 1;
                /* Badge Counter Alerte */
                if (newCnt >= 1) {
                    $alertCounter.text(String(newCnt));
                } else {
                    $alertCounter.hide();
                }
                /* Counter in modal title */
                $('.user-alert-counter-in-title').text(String(newCnt));
                /* Remove targeted alerts from modal */
                $this.parents('.alerte-item').fadeOut('fast');

                message = 'Alerte supprimée';
                messageType = 'success';
            }
            ajaxResponder('#alerts-modal-ajax-result', message, messageType);
        }).fail(function () {
        }).always(function () {
            $('#user-alerts-modal .ajax-loader-wrapper').css('display', 'none');
        });
    });
    /* User Alerts */

    /* AutoTrigger modal opening */
    $('.modal[data-autotrigger="1"]').modal('show');
    /* AutoTrigger modal opening */

    /* Fatal Error closing */
    $body.on('click', '.error-fullscreen .close', function () {
        $('.error-fullscreen').fadeOut('fast');
        return false;
    });
    /* Fatal Error closing */

    /* Ajax module list */
    $('#Idm').on('change', function (e) {
        let $this = $(this),
            $module_id = $this.val()
        let $id_selected_loca = $this.data('selectedloca');
        $.ajax({
            url: URL_SITE + 'demandes/ajax_localisation_list/' + $module_id + '/' + $id_selected_loca,
            type: 'POST'
        }).done(function (response) {
            $('#Idl').html(response).trigger('chosen:updated');
            $('.ajax-loader-wrapper').fadeOut('fast');
            $('#dmdFrm').css('opacity', '1');
        }).fail(function () {
            console.log("Ajax module list fail");
        }).always(function () {
            console.log("Ajax module list complete");
        });
    });
    /* Ajax module list */


    /* Download MAJ */
    $('#maj-modal').on('shown.bs.modal', function () {
        let $modal = $('#maj-modal');
        $('.ajax-loader-wrapper', $modal).css('display', 'flex');
        $.ajax({
            url: $('#majLink').attr('href'),
            type: 'GET'
        }).done(function (response) {
            console.log(response);

            let data = jQuery.parseJSON(response);
            if (data.hasOwnProperty('success')) {
                let link = '<a href=' + data.success + ' download class="btn btn-success btn-block" id="dlMajLink">Télécharger votre mise à jour</a>';
                $modal.find('.modal-body').html(link);
            } else {
                $modal.find('.modal-body').html('<div class="text-danger text-center">'+data.error+'</div>');
                setTimeout(function () {
                    $modal.modal('hide');
                }, 8000);
            }

        }).fail(function (response) {
            console.log("Get Maj Link fail " + response);
        }).always(function () {
            $('.ajax-loader-wrapper', $modal).css('display', 'none');
            console.log("Get Maj Link complete");
        })
    });

    $body.on('click', '#dlMajLink', function () {
        setTimeout(function () {
            $('#maj-modal').modal('hide');
        }, 600);
    });

    /* Reset alert when hidden */
    $('#maj-modal').on('hidden.bs.modal', function () {
        let $modal = $('#maj-modal');
        $modal.find('.modal-body').html(
            '<div class="text-body text-center mb-4">Vérification et préparation de votre mise à jour en cours...</div>' +
            '<div class="ajax-loader-wrapper static"><div class="ajax-loader"></div></div>'
        );
    });
    /* Download MAJ */


    /* Close main error alert */
    $body.on('click', '.close-custom', function (event) {
        event.preventDefault();
        let $target = $(this).data('dismiss');
        $body.removeClass('overflow-hidden');
        $('.' + $target).fadeOut(300);

    });
    /* / Close main error alert */

    /* Filter demand search results */
    $('#demandFilter').on('change', function () {
        let filtersArray = $(this).val().split('/');
        let orderBy = filtersArray[0];
        let order = filtersArray[1];
        console.log(orderBy, order);
        window.location.replace(URL_SITE + 'demandes/0/' + orderBy + '/' + order);
    });
    /* Filter demand search results */

    /* Reset Form Fields */
    $body.on('click', 'button[type="reset"]', function (e) {
        e.preventDefault();
        let frm = $(this).parents('form');
        $.ajax({
            url: URL_SITE + 'demandes/ajax_delete_search',
            type: 'GET'
        }).done(function () {
            $(':input', frm)
                .not(':button, :submit, :reset, :hidden')
                .val('')
                .prop('checked', false)
                .prop('selected', false);

            $('#trigger-advanced-search').removeClass('advanced-search-active');
            $('#advanced-search').css('display', 'none');

        }).fail(function (data) {
        });
    });


    /* Scroll to active news  */
    let $activeNews = $('.news-list-item.active');
    if($activeNews.length) {
        let $scrollTarget = $activeNews.offset().top;
        $('html,body').animate({scrollTop: $scrollTarget},'slow');
    }

});
