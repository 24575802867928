import './validator'
import 'chosen-js'

let frm = $("#userFrm");

//chosen
let $chosenField = $(".chosenField");
$chosenField.chosen({placeholder_text_multiple: 'sélectionner un site'});

$chosenField.change(function () {
    console.log($(this).val());
    if ($(this).val().length !== 0) {
        $chosenField.valid();
    } else {
        frm.valid();
    }
});

frm.validate({
    submitHandler: function (form) {
        if (frm.validate().element(".chosenField") === true) {
            /*$('#submitForm').attr("disabled", "disabled");*/
            form.submit();
        }
    },
    onkeyup: false,
    onclick: false,
    rules: {
        Idt: {
            required: true
        },
        Nom: {
            required: true
        },
        Prenom: {
            required: true
        },
        Tel: {
            required: true
        },
        Mail: {
            required: true,
            email: true
        },
        Fct: {
            required: true
        },
        Login: {
            required: true,
            alphaonly: true
        },
        Pswd: {
            minlength: 7,
            /*alphanumericonly: true,*/
            forcenumeric: true,
            forcealpha: true
        },
        PswdConfirm: {
            equalTo: "#Pswd"
        }
    },
    messages: {

        Idt: {
            required: 'Ce champs est obligatoire'
        },
        Nom: {
            required: 'Ce champs est obligatoire'
        },
        Prenom: {
            required: 'Ce champs est obligatoire'
        },
        Tel: {
            required: 'Ce champs est obligatoire',
            phoneFR: 'Veuillez saisir votre numéro de téléphone dans le format suivant : <i>01-23-45-67-89</i>'
        },
        Mail: {
            required: 'Ce champs est obligatoire',
            email: 'Cette adresse email n\'est pas valide'
        },
        Fct: {
            required: 'Ce champs est obligatoire'
        },
        Login: {
            required: 'Ce champs est obligatoire',
            alphaonly: 'Le login ne doit contenir que des lettres sans accent'
        },
        Pswd: {
            minlength: 'Le mot de passe doit contenir au moins 7 caract&egrave;res alpha-num&eacute;riques',
            alphanumericonly: 'Le mot de passe ne doit contenir que des caract&egrave;res alpha-num&eacute;riques',
            forcenumeric: 'Le mot de passe doit contenir des chiffres',
            forcealpha: 'Le mot de passe doit contenir des lettres'
        },
        PswdConfirm: {
            equalTo: 'Les mots de passe ne sont pas identiques'
        }
    }
});
