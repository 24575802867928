export default ($contactId) => {
    $.ajax({
        url: URL_SITE + 'demandes/ajax_contact_list/' + $contactId,
        type: 'GET'
    }).done(function (response) {
        $('#Idco').html(response);
    }).fail(function () {
        /*console.log("Ajax contact list fail");*/
    }).always(function () {
        /*console.log("Ajax contact list complete");*/
    });
}