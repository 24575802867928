export default ($currentSiteId, $moduleId) => {
    $.ajax({
        url: URL_SITE + 'demandes/ajax_module_list/' + $currentSiteId + '/' + $moduleId,
        type: 'GET'
    }).done(function (response) {
        $('#Idm').html(response);
    }).always(function () {
    }).fail(function () {
        /*console.log("Ajax Module List fail");*/
    }).always(function () {
        /*console.log("Ajax Module List complete");*/
    });
}