import './validator'
import 'chosen-js'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min'
import '../ajax/fillDmdFields'

let $chosenField = $(".chosenField");
$chosenField.chosen({no_results_text: "Aucun r&eacute;sultat"});

$('#dmdFrm').validate({
    submitHandler: function (form) {
        if ($('#dmdFrm').validate().element(".chosenField") === true) {
            $('#button1id').attr("disabled", "disabled");
            form.submit();
        }
    },
    onkeyup: false,
    onclick: false,
    rules: {
        Idco: {
            required: true
        },
        Ids: {
            required: true
        },
        Idm: {
            required: true
        },
        Obs: {
            required: true,
        }
    },
    messages: {
        Idco: {
            required: 'Ce champs est obligatoire'
        },
        Ids: {
            required: 'Ce champs est obligatoire'
        },
        Idm: {
            required: 'Ce champs est obligatoire'
        },
        Obs: {
            required: 'Ce champs est obligatoire'
        }
    }
});

$chosenField.change(function () {
    $chosenField.removeClass('valid');
    if ($(this).val() !== '') {
        $chosenField.valid();
    }
});

//export default () => {}
