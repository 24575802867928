export default ($currentSiteId) => {
    $.ajax({
        url: URL_SITE + 'demandes/ajax_mail_list/' + $currentSiteId,
        type: 'GET'
    }).done(function (response) {
        let $Idm = $('#Idm');
        $('#mailCopyList').html(response);
        $(".chosenField").chosen({
            no_results_text: "Aucun r&eacute;sultat"
        });
        if ($('.triggerModuleChange').length || $Idm.val() !== '') {
            $Idm.trigger('change');
        } else {
            $Idm.css('opacity', '1');
            $('.createEditDmd .ajax-loader-wrapper').fadeOut('fast');
        }

    }).fail(function () {
        /*console.log("Ajax mail list fail");*/
    }).always(function () {
       /*console.log("Ajax mail list complete");*/
    });
}