import './validator'

$("#frmPostCloture").validate({
    submitHandler: function (form) {
        form.submit();
    },
    onkeyup: false,
    onclick: false,
    rules: {
        Obs: {
            required: true
        }
    },
    messages: {
        Obs: {
            required: 'Ce champs est obligatoire'
        }
    }
});
//export default () => {}