import './validator'
import 'chosen-js'
import removeDiacritics from './removeDiacritics'

let frm = $("#frmPostReponse");
frm.validate({
    submitHandler: function (form) {
        let $new_file = $('#PJ')[0].files[0];
        let $newfile_name = $new_file.name;
        let $doc_exist = $('#docExists').val();
        let $forceValidate = $('#forceValidate').val();
        let $unaccented_newfile_name = removeDiacritics($newfile_name);
        if ($new_file !== undefined && $forceValidate === undefined) {
            if ($doc_exist.indexOf($unaccented_newfile_name) >= 0) {
                $('#modalRepondre .modal-body').append(
                    '<div class="alert alert-danger" id="fileExistAlert">' +
                    '<p><strong>Attention ce fichier existe d&eacute;j&agrave;. Que voulez-vous faire?</strong></p>' +
                    '<button type="submit" class="btn btn-danger btn-small" id="forceValidate" value="1">Ecraser le fichier</button>' +
                    '&nbsp;' +
                    '<button class="btn btn-default btn-small" id="replaceFile">Uploader un autre fichier</button></div>');
                $('#modalRepondre .modal-footer .controls button').attr('disabled', 'disabled');
            } else {
                console.log("submit new file");
                form.submit();
            }
        } else {
            console.log("submit no same file found or force validate");
            form.submit();
        }
    },
    onkeyup: false,
    onclick: false,
    rules: {
        Obs: {
            required: true
        }
    },
    messages: {
        Obs: {
            required: 'Ce champs est obligatoire'
        },
        PJ: {
            checkFileExists: true
        }
    }
});

/**
 * Upload a new file
 */
frm.on('click', '#replaceFile', function (event) {
    event.preventDefault();
    $('#PJ').val('');
    $('#modalRepondre .modal-footer .controls button').removeAttr('disabled');
    $('#fileExistAlert').remove();
    return false;
});
//export default () => {}
