import 'jquery-validation'
import 'jquery-validation/dist/localization/messages_fr'

/* Default Form validator Set up */
$.validator.setDefaults({
    errorClass: 'invalid-feedback',
    validClass: 'valid-feedback',
    ignore: ":hidden:not(select)",
    highlight: function (element) {
        $(element).parent().find('.feedback').remove();
        $(element).removeClass('is-valid').addClass('is-invalid');
        $(element).closest('form').addClass('was-validated');
    },
    unhighlight: function (element) {
        $(element).parent().find('.feedback').remove();
        $(element).removeClass('is-invalid').addClass('is-valid');
    },
    errorPlacement: function (error, element) {
        $(element).parent().append($('<div/>', {'class': 'feedback invalid-feedback'}).text(error.text()));
    }
});
$.validator.addMethod("alphanumericonly", function (value, element) {
    return this.optional(element) || /^\w+$/i.test(value);
});
$.validator.addMethod("forcenumeric", function (value, element) {
    return this.optional(element) || /\d/i.test(value);
});
$.validator.addMethod("forcealpha", function (value, element) {
    return this.optional(element) || /[a-zA-Z]/i.test(value);
});
$.validator.addMethod("alphaonly", function (value, element) {
    return this.optional(element) || /^[a-zA-Z]+[0-9]*$/i.test(value);
});
$.validator.addMethod("phoneFR", function (value, element) {
    return this.optional(element) || /^0[0-9]-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}$/i.test(value);
});
/* Default Form validator Set up */
