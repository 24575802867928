import contactList from './contactListByContactId'
import moduleList from './moduleListBySiteId'
import mailList from './mailListBySiteId'


let createDmdIds = $('.createEditDmd #Ids');

createDmdIds.change(function () {

    $('.createEditDmd .ajax-loader-wrapper').css('display', 'flex');
    $('.mailInfo').css('display', 'none');

    let $currentSiteId = (createDmdIds.val() === '') ? 0 : createDmdIds.val();
    //let $Selected = (createDmdIds.data('selected') && '/' !== createDmdIds.data('selected')) ? createDmdIds.data('selected').split("/") : null;
    let $Selected = [];
    let $moduleId = null;
    let $contactId = null;

    if (createDmdIds.data('selected') && '/' !== createDmdIds.data('selected')) {
        $Selected = createDmdIds.data('selected').split("/");
        $moduleId = $Selected[0];
        $contactId = $Selected[1];
    }


    contactList($contactId);
    moduleList($currentSiteId, $moduleId);
    mailList($currentSiteId);
});
//export default () => {}

/* Change trigger by class */
let $triggerChange = $('.triggerChange');
if ($triggerChange.length) {
    $.each($triggerChange, function () {
        $(this).trigger('change');
    });
}
/* Change trigger by class */